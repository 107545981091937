import {Box, Button, FormControl, FormLabel, Input, Stack, useColorModeValue, useToast} from "@chakra-ui/react";
import React, {useState} from "react";
import {Login} from "../../../BackendSDK/Auth/Login/Login";
import axios, {AxiosError} from "axios";
import {useSearchParams} from "react-router-dom";
import {StandardErrorInterface} from "../../../BackendSDK/Interfaces/StandardErrorInterface";

export function LoginForm()
{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams] = useSearchParams();

    const toast = useToast();

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                <Stack spacing={10}>
                    <Button
                        loadingText={"Signing in..."}
                        isLoading={isLoading}
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.500',
                        }}
                        onClick={() => {
                            setIsLoading(true);
                            Login({email, password})
                                .then((res) => {
                                    localStorage.setItem("accessToken", res.data.access_token);
                                    localStorage.setItem("refreshToken", res.data.refresh_token);

                                    const redirectURL = searchParams.get("redirect");

                                    if (redirectURL) {
                                        window.location.replace(redirectURL);
                                    } else {
                                        window.location.replace("/dashboard");
                                    }
                                })
                                .catch((err: AxiosError<StandardErrorInterface>) => {
                                    console.log(err.response?.status);
                                    console.log(err.response?.data);
                                    switch (err.response?.status) {
                                        case axios.HttpStatusCode.BadRequest:
                                            toast({
                                                title: "Invalid Credentials",
                                                description: "Invalid email or password",
                                                status: "error",
                                                duration: 3000,
                                                position: "bottom-right",
                                                isClosable: true,
                                            });
                                            break;
                                        case axios.HttpStatusCode.Forbidden:
                                            toast({
                                                title: "Forbidden",
                                                description: err.response?.data.error ?? "An unknown error occurred",
                                                status: "error",
                                                duration: 3000,
                                                position: "bottom-right",
                                                isClosable: true,
                                            });
                                            break;
                                        case axios.HttpStatusCode.InternalServerError:
                                            toast({
                                                title: "Internal Server Error",
                                                description: "Internal server error",
                                                status: "error",
                                                duration: 3000,
                                                position: "bottom-right",
                                                isClosable: true,
                                            });
                                            break;
                                        default:
                                            toast({
                                                title: "Unknown Error",
                                                description: "An unknown error has occurred",
                                                status: "error",
                                                duration: 3000,
                                                position: "bottom-right",
                                                isClosable: true,
                                            });
                                            break;
                                    }
                                    setIsLoading(false);
                                });
                        }}
                    >
                        Sign in
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}