import {UserInterface} from "../../../BackendSDK/Users/GetUnverifiedUsersToVerify/UsersToVerifyInterface";
import {Flex, Heading, Image, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {GetVerificationPicture} from "../../../BackendSDK/Users/GetVerificationPicture/GetVerificationPicture";

export interface UserToVerifyProps {
    user: UserInterface;
}

export function UserToVerify({user}: UserToVerifyProps)
{
    const [verificationPicture, setVerificationPicture] = useState<string>();

    useEffect(() => {
        GetVerificationPicture(user.verification_picture)
            .then((res) => {
                setVerificationPicture(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [user.verification_picture]);

    return (
        <Flex
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            flexGrow={1}
            maxWidth={"90vw"}
        >
            <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                marginY={"1rem"}
            >
                <Heading>
                    Profile Pictures
                </Heading>
                <Flex
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                    maxWidth={"55vw"}
                    maxHeight={"80vh"}
                >
                    {user.profile_pictures ? user.profile_pictures.map((profilePicture, idx) => (
                        <Image
                            key={idx}
                            src={profilePicture}
                            maxWidth={"24vw"}
                            maxHeight={"50vh"}
                            margin={".25rem"}
                        />
                    )) : (
                        <Text
                            margin={"1rem"}
                        >
                            No profile pictures available
                        </Text>
                    )}

                </Flex>
            </Flex>
            <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                margin={"1rem"}
            >
                <Heading>
                    Verification Picture
                </Heading>
                {verificationPicture ?
                    <Image
                        src={verificationPicture}
                        maxWidth={"24vw"}
                        maxHeight={"50vh"}
                    />
                    : (
                    <Text
                        margin={"1rem"}
                    >
                        No verification picture available
                    </Text>
                )}
            </Flex>
        </Flex>
    );
}