import {useEffect, useState} from "react";
import {GetStatus} from "../../BackendSDK/ManualRush/GetStatus/GetStatus";
import {SidebarWithHeader} from "../../Components/NavBar/SidebarWithHeader/SidebarWithHeader";
import {Loading} from "../../Components/Loading/Loading";
import {Button, Flex, Heading, Text} from "@chakra-ui/react";
import {UpdateStatus} from "../../BackendSDK/ManualRush/UpdateStatus/UpdateStatus";

export function ManualRushPage()
{
    const [active, setActive] = useState<boolean>();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        GetStatus()
            .then((res) => {
                setActive(res.data.active);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    if (isLoading) {
        return (
            <SidebarWithHeader>
                <Loading/>
            </SidebarWithHeader>
        );
    } else if (error) {
        return (
            <SidebarWithHeader>
                <Flex
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"100%"}
                >
                    <Heading
                        as={"h1"}
                        margin={"1rem"}
                    >
                        Manual Rush
                    </Heading>
                    <Heading
                        margin={"1rem"}
                    >
                        Error
                    </Heading>
                    <Text
                        margin={"1rem"}
                    >
                        {error}
                    </Text>
                </Flex>
            </SidebarWithHeader>
        );
    }

    return (
        <SidebarWithHeader>
            <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
            >
                <Heading
                    as={"h1"}
                    margin={"1rem"}
                >
                    Manual Rush
                </Heading>
                <Button
                    size={"lg"}
                    margin={"1rem"}
                    colorScheme={active ? "red" : "green"}
                    onClick={() => {
                        setIsSubmitting(true);
                        UpdateStatus(!active)
                            .then(() => {
                                setActive(!active);
                            })
                            .catch((error) => {
                                setError(error);
                            })
                            .finally(() => {
                                setIsSubmitting(false);
                            });
                    }}
                    isLoading={isSubmitting}
                    loadingText={"Submitting"}
                >
                    {active ? "Stop" : "Start"} Manual Rush
                </Button>
            </Flex>
        </SidebarWithHeader>
    );
}