import {StandardErrorInterface} from "../../BackendSDK/Interfaces/StandardErrorInterface";
import {AxiosError} from "axios";
import {Flex, Heading, Text} from "@chakra-ui/react";

export interface ErrorProps {
    err: AxiosError<StandardErrorInterface>
}

export function Error({err}: ErrorProps)
{
    return (
        <Flex
            flexDirection={"column"}
        >
            <Heading
                margin={"1rem"}
            >
                {err.response?.status}: {err.response?.statusText}
            </Heading>
            <Text
                margin={"1rem"}
            >
                {err.response?.data.error}
            </Text>
        </Flex>
    )
}