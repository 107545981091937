import React, {ReactElement, useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth/useAuth";
import {PageNotFound} from "../../Pages/PageNotFound/PageNotFound";
import {GetAdminLevels} from "../../BackendSDK/Admins/GetAdmins/GetAdminLevels";
import {ParseAccessToken} from "../../Utils/JWT/ParseJWT";
import {Loading} from "../Loading/Loading";

interface RequireAuthProps {
    minimumRole?: string
    children: ReactElement
}

export function RequireAuth({children, minimumRole}: RequireAuthProps)
{
    const isAuthenticated = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState();
    const [levels, setLevels] = useState<string[]>([]);

    useEffect(() => {
        GetAdminLevels()
            .then((res) => {
                setLevels(res.data.levels);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (<Loading height={"100vh"}/>);
    } else if (error) {
        return (<PageNotFound/>);
    } else if (isAuthenticated && isMinimumLevelMet(levels, minimumRole)) {
        return (children);
    } else {
        return (<PageNotFound/>);
    }
}

export function isMinimumLevelMet(levels: string[], minimumRole: string | undefined): boolean
{

    const accessTokenPayload = ParseAccessToken(localStorage.getItem("accessToken") ?? "");

    const minimumRoleIndex = levels.indexOf(minimumRole ?? "");
    const currentLevelIndex = levels.indexOf(accessTokenPayload.authorization_level);

    if (!accessTokenPayload) {
        return false;
    } else if (!minimumRole) {
        return true;
    } else {
        return minimumRoleIndex !== -1 && currentLevelIndex !== -1 && currentLevelIndex >= minimumRoleIndex;
    }
}
