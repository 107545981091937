import {useEffect, useState} from "react";
import {ParseAccessToken, ParseRefreshToken} from "../../Utils/JWT/ParseJWT";
import {Refresh} from "../../BackendSDK/Auth/Refresh/Refresh";

export function useAuth(): boolean
{
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");

        if (accessToken) {
            const accessTokenPayload = ParseAccessToken(accessToken);

            if (accessTokenPayload.exp * 1000 > Date.now()) {
                setIsAuthenticated(true);
            } else {
                const refreshToken = localStorage.getItem("refreshToken");

                if (refreshToken) {
                    const refreshTokenPayload = ParseRefreshToken(refreshToken);

                    if (refreshTokenPayload.exp * 1000 > Date.now()) {
                        Refresh(refreshToken)
                            .then((res) => {
                                localStorage.setItem("accessToken", res.data.access_token);
                                localStorage.setItem("refreshToken", res.data.refresh_token);
                                setIsAuthenticated(true);
                            })
                            .catch(() => {
                                localStorage.removeItem("accessToken");
                                localStorage.removeItem("refreshToken");
                                setIsAuthenticated(false);
                            });
                    } else {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");
                        setIsAuthenticated(false);
                    }
                }
            }
        } else {
            setIsAuthenticated(false);
        }

    }, []);

    return isAuthenticated;
}