import {As, CircularProgress, Flex, Heading} from "@chakra-ui/react";

interface LoadingProps {
    height: string
    headingAs: As
    circleSize: string
    circleThickness: string
}

export function Loading({height, headingAs, circleSize, circleThickness}: LoadingProps)
{
    return (
        <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={height}
        >
            <Heading
                margin={"1rem"}
                as={headingAs}
            >
                Loading...
            </Heading>
            <CircularProgress
                isIndeterminate
                size={circleSize}
                thickness={circleThickness}
                margin={"1rem"}
            />
        </Flex>
    )
}

Loading.defaultProps = {
    height: "100%",
    headingAs: "h1",
    circleSize: "100px",
    circleThickness: "10px",
}
