import {Button, Flex} from "@chakra-ui/react";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

interface PagerProps {
    totalItems: number
    pageSize: number
    currentPage: number
    setPage: (page: number) => void
}

export function Pager({totalItems, pageSize, currentPage, setPage}: PagerProps)
{
    const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : 1;

    return (
        <Flex
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Button
                isDisabled={currentPage === 1}
                onClick={() => setPage(1)}
                marginX={"0.5rem"}
                width={"40px"}
            >
                <ArrowLeftIcon/>
            </Button>
            <Button
                isDisabled={currentPage === 1}
                onClick={() => setPage(currentPage - 1)}
                marginX={"0.5rem"}
                width={"40px"}
            >
                <ChevronLeftIcon/>
            </Button>
            <Flex
                justifyContent={"center"}
                alignItems={"center"}
                marginX={"1rem"}
            >
                {currentPage} / {totalPages}
            </Flex>
            <Button
                isDisabled={currentPage === totalPages}
                onClick={() => setPage(currentPage + 1)}
                marginX={"0.5rem"}
                width={"40px"}
            >
                <ChevronRightIcon/>
            </Button>
            <Button
                isDisabled={currentPage === totalPages}
                onClick={() => setPage(totalPages)}
                marginX={"0.5rem"}
                width={"40px"}
            >
                <ArrowRightIcon/>
            </Button>
        </Flex>
    );
}