import axios, {AxiosResponse} from "axios";
import {
    GetVerificationPicturesAPIKey
} from "../../VerificationPictures/GetVerificationPicturesAPIKey/GetVerificationPicturesAPIKey";

export async function GetVerificationPicture(url: string)
{
    let res: AxiosResponse;
    let verificationPicturesAPIKey = sessionStorage.getItem("verificationPicturesAPIKey");

    if (!verificationPicturesAPIKey) {
        await GetVerificationPicturesAPIKey();
    }

    verificationPicturesAPIKey = sessionStorage.getItem("verificationPicturesAPIKey");

    console.log(verificationPicturesAPIKey);

    res = await axios.get(
        url,
        {
            headers: {
                "X-Instantt-Key": verificationPicturesAPIKey,
            },
            responseType: "blob",
        });

    console.log(res.data);

    return URL.createObjectURL(res.data);
}