import axios from 'axios';
import {LoginInterface} from "./LoginInterface";
import {TokensInterface} from "../TokensInterface";

export async function Login(loginData: LoginInterface)
{
    return await axios.post<TokensInterface>(
        process.env.REACT_APP_BACKEND_URL + "/auth/login",
        loginData,
        {
            headers: {
                'X-Instantt-DeviceID': 'web',
            },
        },
    );
}