import {StandardResponseInterface} from "../../Interfaces/StandardResponseInterface";
import axios from "axios";

export async function UpdateAdminLevel(id: number, level: string)
{
    return await axios.patch<StandardResponseInterface>(
        `${process.env.REACT_APP_BACKEND_URL}/admins/level`,
        {
            id: id,
            level: level
        },
    );
}