import {UpdateUserVerifiedStatusInterface} from "./UpdateUserVerifiedStatusInterface";
import {StandardResponseInterface} from "../../Interfaces/StandardResponseInterface";
import axios from "axios";


export async function UpdateUserVerifiedStatus(user: UpdateUserVerifiedStatusInterface)
{
    return await axios.patch<StandardResponseInterface>(
        `${process.env.REACT_APP_BACKEND_URL}/users/verified-status`,
        user
    );
}