import {Text} from "@chakra-ui/react";
import {SidebarWithHeader} from "../../Components/NavBar/SidebarWithHeader/SidebarWithHeader";

export function DashboardPage()
{
    return (
        <SidebarWithHeader>
            <Text>
                Not implemented yet.
            </Text>
        </SidebarWithHeader>
    )
}