import {IconButton, Td, Tr, useDisclosure} from "@chakra-ui/react";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import {DetailsModal} from "./DetailsModal";
import {AdminInterface} from "../../BackendSDK/Admins/GetAdmins/AdminInterface";
import {EditModal} from "./EditModal";
import {IsAuthenticatedAdminID} from "../../Utils/AuthenticatedAdmin/IsAuthenticatedAdminID";

interface AdminDetailsProps {
    authorizationLevels: string[]
    admin: AdminInterface
}

export function AdminDetails({authorizationLevels, admin}: AdminDetailsProps)
{
    const detailsModal = useDisclosure();
    const editModal = useDisclosure();

    const enableEdit = !IsAuthenticatedAdminID(admin.id);

    let createdAt = new Date(admin.created_at);

    return (
        <Tr key={admin.id}>
            <Td>{admin.id}</Td>
            <Td>{admin.username}</Td>
            <Td>{admin.email}</Td>
            <Td>{admin.level}</Td>
            <Td>{createdAt.toUTCString()}</Td>
            <Td
                padding={"0"}
            >
                <IconButton
                    aria-label={"View"}
                    icon={<ViewIcon/>}
                    onClick={detailsModal.onOpen}
                />
                <DetailsModal
                    isOpen={detailsModal.isOpen}
                    onClose={detailsModal.onClose}
                    admin={admin}
                    createdAt={createdAt}
                />
            </Td>
            <Td
                padding={"0"}
            >
                <IconButton
                    aria-label={"Edit"}
                    icon={<EditIcon/>}
                    onClick={editModal.onOpen}
                    isDisabled={!enableEdit}
                />
                {enableEdit ? (
                    <EditModal
                        isOpen={editModal.isOpen}
                        onClose={editModal.onClose}
                        authorizationLevels={authorizationLevels}
                        admin={admin}
                    />
                ) : null}
            </Td>
        </Tr>
    );
}