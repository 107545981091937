import {Box, Flex, Heading, Link, Text} from "@chakra-ui/react";
import {MainNavBar} from "../../Components/NavBar/MainNavBar/MainNavBar";

export function PageNotFound()
{
    return (
        <Box>
            <MainNavBar
                isAuthHidden
            />
            <Flex
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"100vh"}
            >
                <Heading
                    as={"h1"}
                    size={"3xl"}
                    padding={"20px"}
                >
                    Page Not Found
                </Heading>
                <Text
                    fontSize={"xl"}
                    padding={"20px"}
                >
                    The page you are looking for does not exist.
                </Text>
                <Link
                    href={"/"}
                    color={"blue.400"}
                    fontSize={"xl"}
                    padding={"20px"}
                >
                    Go to Home Page
                </Link>
            </Flex>
        </Box>
    )
}