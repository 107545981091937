import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList, Image, Skeleton,
} from '@chakra-ui/react';
import {
    FiMenu,
    FiChevronDown,
} from 'react-icons/fi';
import {LiaUserShieldSolid} from 'react-icons/lia';
import {LuUserCheck2} from 'react-icons/lu';
import {MdOutlineSpaceDashboard} from 'react-icons/md';
import {PiUsersThree} from 'react-icons/pi';
import { IconType } from 'react-icons';
import React, {PropsWithChildren, useEffect, useState} from "react";
import {ColorModeSwitcher} from "../../../ColorModeSwitcher";
import {isMinimumLevelMet} from "../../RequireAuth/RequireAuth";
import {GetAdminLevels} from "../../../BackendSDK/Admins/GetAdmins/GetAdminLevels";
import {AdminInterface} from "../../../BackendSDK/Admins/GetAdmins/AdminInterface";
import {Me} from "../../../BackendSDK/Admins/Me/Me";
import {AiOutlineThunderbolt} from "react-icons/ai";

interface LinkItemProps {
    name: string
    icon: IconType
    to: string
    minimumLevel?: string
}

interface NavItemProps extends FlexProps {
    icon: IconType
    to: string
    children: React.ReactNode
}

interface MobileProps extends FlexProps {
    onOpen: () => void
}

interface SidebarProps extends BoxProps {
    onClose: () => void
}

const LinkItems: Array<LinkItemProps> = [
    {name: 'Dashboard', icon: MdOutlineSpaceDashboard, to: "/dashboard"},
    {name: 'Users Verification', icon: LuUserCheck2, to: "/users-verification", minimumLevel: "user_management"},
    {name: 'Users', icon: PiUsersThree, to: "/users", minimumLevel: "read_only"},
    {name: 'Admins', icon: LiaUserShieldSolid, to: "/admins", minimumLevel: "admin"},
    {name: 'Rush', icon: AiOutlineThunderbolt, to: "/rush", minimumLevel: "admin"},
]

function SidebarContent({ onClose, ...rest }: SidebarProps)
{
    const [error, setError] = useState();
    const [levels, setLevels] = useState<string[]>([]);

    useEffect(() => {
        GetAdminLevels()
            .then((res) => {
                setLevels(res.data.levels);
            })
            .catch((err) => {
                setError(err);
            })
    }, []);

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.800')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="center">
                <Image
                    src={"/logo.png"}
                    height={"80%"}
                />
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => {

                if (!error && levels.length !== 0 && isMinimumLevelMet(levels, link.minimumLevel)) {
                    return (
                        <NavItem key={link.name} icon={link.icon} to={link.to}>
                            {link.name}
                        </NavItem>
                    );
                } else {
                    return null;
                }
            })}
        </Box>
    )
}

function NavItem({ icon, children, to, ...rest }: NavItemProps)
{
    return (
        <Box
            as="a"
            href={to}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="20"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

function MobileNav({ onOpen, ...rest }: MobileProps)
{
    const [admin, setAdmin] = useState<AdminInterface>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState();

    useEffect(() => {
        Me()
            .then((res) => {
                setAdmin(res.data.admin);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err);
                setIsLoading(false);
            });
    }, []);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.800')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                Logo
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <ColorModeSwitcher/>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    name={admin && admin.username}
                                    src={
                                        'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
                                    }
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Skeleton isLoaded={!error && !isLoading && admin !== undefined}>
                                        <Text fontSize="sm">
                                            {admin && admin.username}
                                        </Text>
                                    </Skeleton>
                                    <Skeleton isLoaded={!error && !isLoading && admin !== undefined}>
                                        <Text fontSize="xs" color="gray.600">
                                            {admin && admin.level}
                                        </Text>
                                    </Skeleton>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.700')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem>Profile</MenuItem>
                            <MenuItem>Settings</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => {window.location.replace("/logout")}}>Log out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    )
}

export function SidebarWithHeader({children}: PropsWithChildren)
{
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    )
}
