import {AdminInterface} from "../../BackendSDK/Admins/GetAdmins/AdminInterface";
import {
    Box, Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    Text, useToast
} from "@chakra-ui/react";
import {useState} from "react";
import {UpdateAdminLevel} from "../../BackendSDK/Admins/GetAdmins/UpdateAdminLevel";
import {AxiosError} from "axios";
import {StandardErrorInterface} from "../../BackendSDK/Interfaces/StandardErrorInterface";

interface EditModalProps {
    isOpen: boolean
    onClose: () => void
    authorizationLevels: string[]
    admin: AdminInterface
}

export function EditModal({isOpen, onClose, authorizationLevels, admin}: EditModalProps)
{
    const [authorizationLevel, setAuthorizationLevel] = useState<string>(admin.level);

    const toast = useToast();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
        <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    Edit {admin.username}
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex
                        flexDirection={"row"}
                    >
                        <Box
                            marginRight={"1rem"}
                        >
                            <Text marginTop={"0.5rem"} fontWeight={"bold"}>Authorization Level: </Text>
                        </Box>
                        <Box>
                            <Select
                                placeholder={"Select authorization level"}
                                value={authorizationLevel}
                                onChange={(e) => setAuthorizationLevel(e.target.value)}
                            >
                                {authorizationLevels && authorizationLevels.map((level) => {
                                    return (
                                        <option key={level} value={level}>{level}</option>
                                    );
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    {authorizationLevel !== admin.level && (
                        <Flex
                            flexDirection={"row"}
                            marginTop={"1rem"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Text color={"#da3633"} marginRight={"0.25rem"}>{admin.level}</Text>
                            <Text marginX={"0.25rem"}>{"->"}</Text>
                            <Text color={"#238636"} marginLeft={"0.25rem"}>{authorizationLevel}</Text>
                        </Flex>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        marginRight={"0.5rem"}
                        onClick={() => {
                            setAuthorizationLevel(admin.level);
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme={"blue"}
                        onClick={() => {
                            UpdateAdminLevel(admin.id, authorizationLevel)
                                .then(() => {
                                    admin.level = authorizationLevel;
                                    toast({
                                        title: "Admin Updated",
                                        description: "Admin updated successfully",
                                        status: "success",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                }).catch((err: AxiosError<StandardErrorInterface>) => {
                                    toast({
                                        title: "Error Updating Admin",
                                        description: err.response?.data.error ?? "Unknown Error",
                                        status: "error",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setAuthorizationLevel(admin.level);
                                });
                            onClose();
                        }}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}