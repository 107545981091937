import {Box, CircularProgress, Flex, Text} from "@chakra-ui/react";
import {MainNavBar} from "../../../Components/NavBar/MainNavBar/MainNavBar";
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {Logout} from "../../../BackendSDK/Auth/Logout/Logout";
import axios, {AxiosError} from "axios";

export function LogoutPage()
{
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    useEffect(() => {
        Logout()
            .then((_) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                setIsLoggedOut(true);
            })
            .catch((err: AxiosError) => {
                if (err.status === axios.HttpStatusCode.Forbidden) {

                }
            });
    }, [])

    if (isLoggedOut) {
        return (<Navigate to={"/"}/>);
    } else {
        return (
            <Box>
                <MainNavBar
                    isAuthHidden
                />
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    height={"100vh"}
                >
                    <CircularProgress
                        isIndeterminate
                        size={"150px"}
                        thickness={"5px"}
                        padding={"20px"}
                    />
                    <Text
                        fontSize={"3xl"}
                        padding={"20px"}
                    >
                        Logging out ...
                    </Text>
                </Flex>
            </Box>
        );
    }
}