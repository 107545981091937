import axios from 'axios';
import {Refresh} from "../Refresh/Refresh";
import {ParseAccessToken, ParseRefreshToken} from "../../../Utils/JWT/ParseJWT";

export function SetupAuthorizationInterceptor()
{
    axios.interceptors.request.use(
        (config) => {
            try {
                if (!process.env.REACT_APP_BACKEND_URL) {
                    throw new Error("REACT_APP_BACKEND_URL is not set.");
                }

                if (config.url?.startsWith(process.env.REACT_APP_BACKEND_URL) && !config.url?.endsWith("/auth/register") &&
                    !config.url?.endsWith("/auth/login") && !config.url?.endsWith("/auth/refresh")) {

                    const accessToken = localStorage.getItem('accessToken');
                    const refreshToken = localStorage.getItem('refreshToken');

                    if (accessToken) {
                        const payload = ParseAccessToken(accessToken);
                        if (payload.exp < Date.now()) {
                            RefreshTokens(refreshToken);
                        }
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
                    } else {
                        RefreshTokens(refreshToken);
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
                    }
                }
            } catch (e) {
                const controller = new AbortController();
                controller.abort();
                return {
                    ...config,
                    signal: controller.signal,
                }
            }
            return config;
        },
    );
}

function RefreshTokens(refreshToken: string | null)
{
    if (refreshToken) {

        const payload = ParseRefreshToken(refreshToken);

        if (payload.exp < Date.now()) {
            CleanupTokens();
        }

        Refresh(refreshToken)
            .then((response) => {
                localStorage.setItem('accessToken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
            })
            .catch((_) => {
            });

    } else {
        CleanupTokens();
    }
}

function CleanupTokens()
{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.replace('/login?redirect=' + window.location.pathname);
    throw new Error("Expired or missing refresh token.");
}
