import axios from "axios";
import {VerificationPicturesAPIKeyInterface} from "./VerificationPicturesAPIKeyInterface";

export async function GetVerificationPicturesAPIKey()
{
    const res = await axios.get<VerificationPicturesAPIKeyInterface>(
        `${process.env.REACT_APP_BACKEND_URL}/verification-pictures/api-key`,
    )

    sessionStorage.setItem("verificationPicturesAPIKey", res.data.api_key);
}