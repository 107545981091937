import {AdminInterface} from "../../BackendSDK/Admins/GetAdmins/AdminInterface";
import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";

interface DetailsModalProps {
    isOpen: boolean
    onClose: () => void
    admin: AdminInterface
    createdAt: Date
}

export function DetailsModal({isOpen, onClose, admin, createdAt}: DetailsModalProps)
{
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"lg"}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    {admin.username}'s Details
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex
                        flexDirection={"row"}
                    >
                        <Box
                            marginRight={"1rem"}
                        >
                            <Text fontWeight={"bold"}>ID: </Text>
                            <Text fontWeight={"bold"}>Username: </Text>
                            <Text fontWeight={"bold"}>Email: </Text>
                            <Text fontWeight={"bold"}>Authorization Level: </Text>
                            <Text fontWeight={"bold"}>Created At: </Text>
                        </Box>
                        <Box>
                            <Text>{admin.id}</Text>
                            <Text>{admin.username}</Text>
                            <Text>{admin.email}</Text>
                            <Text>{admin.level}</Text>
                            <Text>{createdAt.toUTCString()}</Text>
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}