import {Button, Flex, Link, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {
    GetUnverifiedUsersToVerifyCount
} from "../../../BackendSDK/Users/GetUnverifiedUsersToVerify/GetUnverifiedUsersToVerifyCount";

export function UnverifiedUsersToVerify()
{
    const [unverifiedUsersCount, setUnverifiedUsersCount] = useState<number>(0);
    const [countError, setCountError] = useState();

    useEffect(() => {
        GetUnverifiedUsersToVerifyCount()
            .then((res) => {
                setUnverifiedUsersCount(res.data.count);
            })
            .catch((err) => {
                setCountError(err);
            });
    }, []);

    return (
        <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginY={"1rem"}
        >
            <Text
                marginX={"1rem"}
            >
                Unverified users count: {countError ? "NaN" : unverifiedUsersCount}
            </Text>
            <Link
                href={"/users-verification/verify"}
                marginX={"1rem"}
            >
                <Button
                    isDisabled={countError ? true : unverifiedUsersCount === 0}
                    size={"lg"}
                >
                    Verify users
                </Button>
            </Link>
        </Flex>
    );
}