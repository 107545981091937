import {ParseAccessToken} from "../JWT/ParseJWT";

export function IsAuthenticatedAdminID(id: number): boolean
{
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken === null) {
        return false;
    }

    const payload = ParseAccessToken(accessToken);

    return payload.id === id;
}