import {
    Box,
    Button, Flex,
    FormControl, FormErrorMessage, FormLabel, Input,
    InputGroup,
    InputRightElement,
    Link,
    Stack,
    Text,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import React, {useState} from "react";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {Register} from "../../../BackendSDK/Auth/Register/Register";

export function RegisterForm()
{
    const emailRegex = new RegExp('^[^.\\s][\\w\\-.{2,}]+@([\\w-]+\\.)+[\\w-]{2,}$');
    const passwordUppercaseLettersRegex = new RegExp('^.*[A-Z]+.*$');
    const passwordLowercaseLettersRegex = new RegExp('^.*[a-z]+.*$');
    const passwordNumbersRegex = new RegExp('^.*[0-9]+.*$');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const isEmailValid = emailRegex.test(email);
    const doEmailsMatch = email === confirmEmail;
    const isPasswordValid = password.length >= 16 && !password.includes(username) && !password.includes(email) &&
        passwordUppercaseLettersRegex.test(password) && passwordLowercaseLettersRegex.test(password) &&
        passwordNumbersRegex.test(password);
    const doPasswordsMatch = password === confirmPassword;

    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast();

    return (
        <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
        >
            <Stack spacing={4}>
                <FormControl id="username" isRequired>
                    <FormLabel>Username</FormLabel>
                    <Input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormControl>
                <FormControl
                    id="email"
                    isInvalid={!(email.length === 0 || isEmailValid)}
                    isRequired
                >
                    <FormLabel>Email address</FormLabel>
                    <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormErrorMessage>
                        Please enter a valid email address
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    id="confirm-email"
                    isInvalid={!(confirmEmail.length === 0 || doEmailsMatch)}
                    isRequired
                >
                    <FormLabel>Confirm email address</FormLabel>
                    <Input
                        type="email"
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                    <FormErrorMessage>
                        Email addresses do not match
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    id="password"
                    isInvalid={!(password.length === 0 || isPasswordValid)}
                    isRequired
                >
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement h={'full'}>
                            <Button
                                variant={'ghost'}
                                onClick={() =>
                                    setShowPassword((showPassword) => !showPassword)
                                }>
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        <Flex
                            direction={'column'}
                        >
                            Password does not meet requirements:
                            {password.length < 16 ?
                                <Box>
                                    - Password must be at least 16 characters long
                                </Box> : null
                            }
                            {username.length > 0 && password.includes(username) ?
                                <Box>
                                    - Password cannot contain username
                                </Box> : null
                            }
                            {email.length > 0 && password.includes(email) ?
                                <Box>
                                    - Password cannot contain email address
                                </Box> : null
                            }
                            {!passwordUppercaseLettersRegex.test(password) ?
                                <Box>
                                    - Password must contain at least one uppercase letter
                                </Box> : null
                            }
                            {!passwordLowercaseLettersRegex.test(password) ?
                                <Box>
                                    - Password must contain at least one lowercase letter
                                </Box> : null
                            }
                            {!passwordNumbersRegex.test(password) ?
                                <Box>
                                    - Password must contain at least one number
                                </Box> : null
                            }
                        </Flex>
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    id="confirm-password"
                    isInvalid={!(confirmPassword.length === 0 || doPasswordsMatch)}
                    isRequired
                >
                    <FormLabel>Confirm password</FormLabel>
                    <InputGroup>
                        <Input
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <InputRightElement h={'full'}>
                            <Button
                                variant={'ghost'}
                                onClick={() =>
                                    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                                }
                            >
                                {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        Passwords do not match
                    </FormErrorMessage>
                </FormControl>
                <Stack spacing={10} pt={2}>
                    <Button
                        loadingText="Submitting"
                        isLoading={isLoading}
                        size="lg"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.500',
                        }}
                        isDisabled={username.length === 0 || !isEmailValid || !doEmailsMatch || !isPasswordValid ||
                            !doPasswordsMatch}
                        onClick={() => {
                            setIsLoading(true);
                            Register({username, email, password})
                                .then((_) => {
                                    toast({
                                        title: 'Account created',
                                        description: "You've successfully created an account",
                                        status: 'success',
                                        duration: 3000,
                                        position: 'bottom-right',
                                        isClosable: true,
                                    })
                                })
                                .catch((_) => {
                                    toast({
                                        title: 'Error',
                                        description: "An error occurred while creating your account",
                                        status: 'error',
                                        duration: 3000,
                                        position: 'bottom-right',
                                        isClosable: true,
                                    })
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                });
                        }}
                    >
                        Sign up
                    </Button>
                </Stack>
                <Stack pt={6}>
                    <Text align={'center'}>
                        Already a user?{' '}
                        <Link
                            color={'blue.400'}
                            href={'/login'}
                        >
                            Login
                        </Link>
                    </Text>
                </Stack>
            </Stack>
        </Box>
    );
}