import {useEffect, useState} from "react";
import {Loading} from "../../../Components/Loading/Loading";
import {
    GetUnverifiedUsersToVerify
} from "../../../BackendSDK/Users/GetUnverifiedUsersToVerify/GetUnverifiedUsersToVerify";
import {
    UserInterface,
} from "../../../BackendSDK/Users/GetUnverifiedUsersToVerify/UsersToVerifyInterface";
import {AxiosError} from "axios";
import {StandardErrorInterface} from "../../../BackendSDK/Interfaces/StandardErrorInterface";
import {Button, Flex, Heading, Link, useToast} from "@chakra-ui/react";
import {Error} from "../../../Components/Error/Error";
import {UserToVerify} from "./UserToVerify";
import {SidebarWithHeader} from "../../../Components/NavBar/SidebarWithHeader/SidebarWithHeader";
import {UpdateUserVerifiedStatus} from "../../../BackendSDK/Users/UpdateUserVerifiedStatus/UpdateUserVerifiedStatus";

export function UsersVerificationPage()
{
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<AxiosError<StandardErrorInterface>>();
    const [users, setUsers] = useState<UserInterface[]>([]);
    const [userIdx, setUserIdx] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const toast = useToast();

    useEffect(() => {
        if (userIdx >= users?.length ?? 0) {
            GetUnverifiedUsersToVerify(0, 100)
                .then((res) => {
                    if (res.data.users === null) {
                        window.location.replace("/users-verification");
                    }
                    setUsers(res.data.users);
                })
                .catch((err: AxiosError<StandardErrorInterface>) => {
                    setError(err);
                })
                .finally(() => {
                    setError(undefined);
                    setIsLoading(false);
                });
        }
    }, [userIdx, users?.length]);

    if (isLoading) {
        return (
            <Loading/>
        );
    } else if (error) {
        return (
            <Error err={error}/>
        )
    } else {
        return (
            <SidebarWithHeader>
                <Flex
                    flexDirection={"column"}
                    maxWidth={"90vw"}
                    flexGrow={1}
                >
                    {users && users[userIdx] ?
                        <>
                        <UserToVerify user={users[userIdx]}/>
                        <Flex
                            flexDirection={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            marginY={"1rem"}
                            maxWidth={"90vw"}
                            flexGrow={1}
                            paddingRight={"1rem"}
                        >
                            <Button
                                marginX={"1rem"}
                                size={"lg"}
                                isLoading={isSubmitting}
                                loadingText={"Submitting"}
                                onClick={() => {
                                    setIsSubmitting(true);
                                    UpdateUserVerifiedStatus({id: users[userIdx].id, is_verified: false})
                                        .then(() => {
                                            toast({
                                                title: "User verification refused",
                                                description: "User has been refused",
                                                status: "success",
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                            setUserIdx(userIdx + 1);
                                        })
                                        .catch((err: AxiosError<StandardErrorInterface>) => {
                                            toast({
                                                title: "User verification refusal failed",
                                                description: err.response?.data.error ?? "Unknown error",
                                                status: "error",
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                        })
                                        .finally(() => {
                                            setIsSubmitting(false);
                                        });
                                }}
                            >
                                Refuse
                            </Button>
                            <Button
                                marginX={"1rem"}
                                colorScheme={"blue"}
                                size={"lg"}
                                isLoading={isSubmitting}
                                loadingText={"Submitting"}
                                onClick={() => {
                                    setIsSubmitting(true);
                                    UpdateUserVerifiedStatus({id: users[userIdx].id, is_verified: true})
                                        .then(() => {
                                            toast({
                                                title: "User verified",
                                                description: "User has been verified",
                                                status: "success",
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                            setUserIdx(userIdx + 1);
                                        })
                                        .catch((err: AxiosError<StandardErrorInterface>) => {
                                            toast({
                                                title: "User verification failed",
                                                description: err.response?.data.error ?? "Unknown error",
                                                status: "error",
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                        })
                                        .finally(() => {
                                            setIsSubmitting(false);
                                        });
                                }}
                            >
                                Accept
                            </Button>
                        </Flex>
                        </>
                        :
                        <Flex
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Heading
                                margin={"1rem"}
                            >
                                No users to verify
                            </Heading>
                            <Link
                                href={"/users-verification"}
                                margin={"1rem"}
                            >
                                <Button>
                                    Go back
                                </Button>
                            </Link>
                        </Flex>
                    }
                </Flex>
            </SidebarWithHeader>
        );
    }
}