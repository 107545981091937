import {Flex, Heading} from "@chakra-ui/react";
import {SidebarWithHeader} from "../../../Components/NavBar/SidebarWithHeader/SidebarWithHeader";
import {UnverifiedUsersToVerify} from "./UnverifiedUsersToVerify";

export function UsersVerificationMainPage()
{

    return (
        <SidebarWithHeader>
            <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Heading
                    marginY={"1rem"}
                >
                    Users verification
                </Heading>
                <UnverifiedUsersToVerify/>
            </Flex>
        </SidebarWithHeader>
    );
}