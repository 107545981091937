import {AccessTokenPayloadInterface, RefreshTokenPayloadInterface} from "./JWTPayloadInterface";

export function ParseAccessToken(accessToken: string): AccessTokenPayloadInterface
{
    const base64UrlEncodedPayload = accessToken.split('.')[1];
    const base64Payload = base64UrlEncodedPayload.replace('-', '+').replace('_', '/');
    const payload = JSON.parse(window.atob(base64Payload));

    payload.exp *= 1000;

    return payload;
}

export function ParseRefreshToken(refreshToken: string): RefreshTokenPayloadInterface
{
    const base64UrlEncodedPayload = refreshToken.split('.')[1];
    const base64Payload = base64UrlEncodedPayload.replace('-', '+').replace('_', '/');
    const payload = JSON.parse(window.atob(base64Payload));

    payload.exp *= 1000;

    return payload;
}