import {AdminsInterface} from "./AdminInterface";
import axios from "axios";


export async function GetAdmins(limit: number, page: number, query: string)
{
    if (query !== "") {
        return await axios.get<AdminsInterface>(
            `${process.env.REACT_APP_BACKEND_URL}/admins?limit=${limit}&page=${page}&query=${query}`
        );
    } else {
        return await axios.get<AdminsInterface>(
            `${process.env.REACT_APP_BACKEND_URL}/admins?limit=${limit}&page=${page}`
        );
    }
}