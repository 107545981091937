import {MainNavBar} from "../../Components/NavBar/MainNavBar/MainNavBar";
import {useAuth} from "../../hooks/useAuth/useAuth";
import {Navigate} from "react-router-dom";

export function HomePage()
{
    const isAuthenticated = useAuth();

    if (isAuthenticated) {
        return (
            <Navigate to={"/dashboard"}/>
        );
    }

    return (
        <MainNavBar/>
    );
}