import {MainNavBar} from "../../../Components/NavBar/MainNavBar/MainNavBar";
import {LoginForm} from "./LoginForm";
import React from "react";
import {Box, Flex, Heading, Stack, useColorModeValue} from "@chakra-ui/react";
import {useAuth} from "../../../hooks/useAuth/useAuth";
import {Navigate} from "react-router-dom";

export function LoginPage()
{
    const isAuthenticated = useAuth();

    const background = useColorModeValue("gray.50", "gray.800");

    if (isAuthenticated) {
        return (
            <Navigate to={"/dashboard"}/>
        );
    }

    return (
        <Box>
            <MainNavBar/>
            <Flex
                minH={'80vh'}
                align={'center'}
                justify={'center'}
                bg={background}
            >
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                    </Stack>
                    <LoginForm/>
                </Stack>
            </Flex>
        </Box>
    );
}