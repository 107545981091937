import {SidebarWithHeader} from "../../Components/NavBar/SidebarWithHeader/SidebarWithHeader";
import {
    Flex,
    Heading, IconButton,
    Input,
    InputGroup,
    InputRightElement, Table, TableContainer, Tbody, Th, Thead, Tr,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {GetAdmins} from "../../BackendSDK/Admins/GetAdmins/GetAdmins";
import { AdminInterface } from "../../BackendSDK/Admins/GetAdmins/AdminInterface";
import {AxiosError} from "axios";
import {StandardErrorInterface} from "../../BackendSDK/Interfaces/StandardErrorInterface";
import {Pager} from "../../Components/Pager/Pager";
import {AdminDetails} from "./AdminDetails";
import "./SearchBar.css"
import {GetAuthorizedAdminLevels} from "../../BackendSDK/Admins/GetAdmins/GetAuthorizedAdminLevels";
import {Loading} from "../../Components/Loading/Loading";

export function AdminsPage()
{
    const [page, setPage] = useState<number>(1);
    const [admins, setAdmins] = useState<AdminInterface[]>([]);
    const [adminCount, setAdminCount] = useState<number>(0);
    const [authorizationLevels, setAuthorizationLevels] = useState<string[]>([]);
    const [query, setQuery] = useState<string>("");
    const [submitSearch, setSubmitSearch] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const toast = useToast();

    useEffect(() => {
        GetAuthorizedAdminLevels()
            .then((res) => {
                setAuthorizationLevels(res.data.levels);
            })
    }, []);

    useEffect(() => {
        if (submitSearch) {
            setIsLoading(true);
            GetAdmins(20, page, query)
                .then((res) => {
                    setAdmins(res.data.admins);
                    setAdminCount(res.data.count);
                })
                .catch((err: AxiosError<StandardErrorInterface>) => {
                    toast({
                        title: "Error Getting Admins",
                        description: err.response?.data.error ?? "Unknown Error",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setSubmitSearch(false);
                });
        }
    }, [page, query, submitSearch, toast]);

    return (
        <SidebarWithHeader>
            <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Heading
                    margin={"1rem"}
                >
                    Admins
                </Heading>
                <form
                    className={"admin-search-bar-form"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        setSubmitSearch(true);
                    }}
                >
                <InputGroup
                    size={"lg"}
                    w={{base: "100%", md: "50%"}}
                    margin={"1rem"}
                >
                    <Input
                        placeholder={"Search"}
                        backgroundColor={useColorModeValue("white", "gray.800")}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label={"Search"}
                            icon={<SearchIcon/>}
                            type={"submit"}
                        />
                    </InputRightElement>
                </InputGroup>
                </form>
            </Flex>
            <TableContainer
                margin={"1rem"}
            >
                {isLoading ? <Loading headingAs={"h2"} circleSize={"80px"}/> : (
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Username</Th>
                                <Th>Email</Th>
                                <Th>Authorization Level</Th>
                                <Th>Created At</Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {admins && admins.map((admin) =>
                                <AdminDetails key={admin.id} authorizationLevels={authorizationLevels} admin={admin}/>
                            )}
                        </Tbody>
                    </Table>
                )}
            </TableContainer>
            <Pager totalItems={adminCount} pageSize={20} currentPage={page} setPage={setPage}/>
        </SidebarWithHeader>
    );
}