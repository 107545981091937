import axios from "axios";
import {TokensInterface} from "../TokensInterface";

export async function Refresh(refreshToken: string)
{
    return await axios.post<TokensInterface>(
        process.env.REACT_APP_BACKEND_URL + "/auth/refresh",
        {},
        {
            headers: {
                Authorization: "Bearer " + refreshToken,
            }
        }
    );
}