import * as React from "react"
import {
    ChakraProvider,
    theme,
} from "@chakra-ui/react"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomePage} from "./Pages/Home/HomePage";
import {LoginPage} from "./Pages/Auth/Login/LoginPage";
import {RegisterPage} from "./Pages/Auth/Register/RegisterPage";
import {LogoutPage} from "./Pages/Auth/Logout/LogoutPage";
import {PageNotFound} from "./Pages/PageNotFound/PageNotFound";
import {DashboardPage} from "./Pages/Dashboard/DashboardPage";
import {RequireAuth} from "./Components/RequireAuth/RequireAuth";
import {AdminsPage} from "./Pages/Admins/AdminsPage";
import {UsersVerificationMainPage} from "./Pages/UsersVerification/UsersVerificationMain/UsersVerificationMainPage";
import {UsersVerificationPage} from "./Pages/UsersVerification/UsersVerification/UsersVerificationPage";
import {ManualRushPage} from "./Pages/ManualRush/ManualRushPage";

export const App = () => (
    <ChakraProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<HomePage/>}/>
                <Route path={"/login"} element={<LoginPage/>}/>
                <Route path={"/register"} element={<RegisterPage/>}/>
                <Route path={"/logout"} element={<LogoutPage/>}/>

                <Route path={"/dashboard"} element={
                    <RequireAuth>
                        <DashboardPage/>
                    </RequireAuth>
                }/>
                <Route path={"/admins"} element={
                    <RequireAuth minimumRole={"admin"}>
                        <AdminsPage/>
                    </RequireAuth>
                }/>
                <Route path={"/users-verification"} element={
                    <RequireAuth minimumRole={"user_management"}>
                        <UsersVerificationMainPage/>
                    </RequireAuth>
                }/>
                <Route path={"/users-verification/verify"} element={
                    <RequireAuth minimumRole={"user_management"}>
                        <UsersVerificationPage/>
                    </RequireAuth>
                }/>
                <Route path={"/rush"} element={
                    <RequireAuth minimumRole={"admin"}>
                        <ManualRushPage/>
                    </RequireAuth>
                }/>

                <Route path={"*"} element={<PageNotFound/>}/>
            </Routes>
        </BrowserRouter>
    </ChakraProvider>
)
